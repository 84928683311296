


































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { RewardViewModel } from '../../viewmodel/reward-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject({}) vm!: RewardViewModel

  toolbarOptions = ['Last 7 days', 'Last 30 days']
  toolbarOption = this.toolbarOptions[0]

  changeChartFilter(value) {
    if (value === this.toolbarOptions[1]) {
      this.toolbarOption = this.toolbarOptions[1]
      this.vm.changeChartFilter('30d')
    } else if (value === this.toolbarOptions[0]) {
      this.toolbarOption = this.toolbarOptions[0]
      this.vm.changeChartFilter('7d')
    }
  }
}
